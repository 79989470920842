import React from "react"
import AccountFooter from "./accountFooter"
import "../assets/styles/components/accountLayout.scss"
import AccountHeader from "./accountHeader"

const AccountLayout = (props: { [keys: string]: any }) => {
  const { to, title, formInput, isDisabled, children } = props

  const handleBack = () => {
    window.history.back()
  }

  return (
    <div className="account-layout">
      <div className="back">
        <button onClick={handleBack} className="back-button">
          <svg
            className="svg-button"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
            focusable="false"
          >
            <g fill="none">
              <path d="m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932"></path>
            </g>
          </svg>
        </button>
      </div>
      <AccountHeader title={title} />
      <div className="wrapper-one">
        <div className="wrapper-two">
          <div className="wrapper-three">
            <div className="form-content">{children}</div>
          </div>
        </div>
      </div>
      <AccountFooter formInput={formInput} to={to} isDisabled={isDisabled} />
    </div>
  )
}

export default AccountLayout
