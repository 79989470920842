import React from "react"
import "../assets/styles/components/accountHeader.scss"

const AccountHeader = (props: { [key: string]: any }) => {
  const { title } = props

  return (
    <header className="account-header">
      <div></div>
      <div>
        <p className="header-title">{title}</p>
      </div>
      <div></div>
    </header>
  )
}

export default AccountHeader
