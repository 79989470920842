import React from "react"
import { Link } from "gatsby"
import "../assets/styles/components/accountFooter.scss"
import { handleKycNextBtn } from "../helper/firebase/transaction/profile.trx"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "@reduxjs/toolkit"

const AccountFooter = (props: { [keys: string]: any }) => {
  const { to, formInput, isDisabled } = props

  const userStatus = useSelector((state: any) => state.auth.userStatus)
  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)

  const dispatch = useDispatch()

  const nxtKycCustomHook = handleKycNextBtn(dispatch as Dispatch)

  return (
    <footer className="footer">
      <button className="exit-btn">Exit</button>
      {isDisabled ? (
        <button form="form-id" type="submit" className="next-btn">
          Next
        </button>
      ) : (
        <Link to={to}>
          <button
            form="form-id"
            type="submit"
            onClick={() => nxtKycCustomHook(formInput, kycProfileStatus, userStatus)}
            className="next-btn"
          >
            Next
          </button>
        </Link>
      )}
    </footer>
  )
}

export default AccountFooter
