import { useState, useEffect } from "react";

export const useInputChange = (): [Record<string, any>, (e: any) => void] => {
  if (typeof window === "undefined") {
    // Return safe defaults during SSR
    return [{}, () => {}];
  }

  const [input, setInput] = useState<Record<string, any>>({});

  const handleInputChange = (e: any) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  return [input, handleInputChange];
};