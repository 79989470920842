import React from "react"
import "../assets/styles/components/radioInput.scss"

interface RadioButtonProps {
  name: string
  value: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  children: any
}
const RadioButton: React.FC<RadioButtonProps> = ({ name, value, checked, onChange, children }) => {
  return (
    <label className="radio-input">
      <input
        className={checked ? "radio radio-checked" : "radio radio-unchecked"}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {children}
    </label>
  )
}

export default RadioButton
