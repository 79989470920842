import React, { useEffect } from "react"
import "../assets/styles/components/isBeneficialOwner.scss"
import { useInputChange } from "../custom-hook/useForm"
import AccountInput from "./accountInput"
import RadioButton from "./accountRadioInput"

const IsBeneficialOwner = (props: { [keys: string]: any })=> {
  const { setIsDisabled, formInputRef } = props
  const [input, handleInputChange] = useInputChange()

  const isCompleted = !input.isBeneficialOwner || (input.isBeneficialOwner === "yes" ? !input.percentOwned : null)
  useEffect(() => {
    setIsDisabled(isCompleted)
    if (Object.keys(input).length != 0) Object.assign(formInputRef.current, input)
  }, [isCompleted, input])

  return (
    <div className="beneficial-owner">
      <h3 className="h3-heading">Are you a beneficial owner?</h3>
      <span className="desc-1">
        A beneficial owner is anyone who directly or indirectly owns or controls 25% or more of the business.
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </span>
      <div className="beneficial-form">
        <RadioButton
          name="isBeneficialOwner"
          value="yes"
          checked={input.isBeneficialOwner === "yes"}
          onChange={handleInputChange}
        >
          Yes
        </RadioButton>
        <RadioButton
          name="isBeneficialOwner"
          value="no"
          checked={input.isBeneficialOwner === "no"}
          onChange={handleInputChange}
        >
          No
        </RadioButton>
      </div>

      {input.isBeneficialOwner === "yes" ? (
        <div className="percent-owned-form">
          <h6>Percent owned or controlled</h6>
          <AccountInput
            type={"text"}
            name={"percentOwned"}
            onChange={handleInputChange}
            label={"Ex: 25"}
            required={"required"}
          />
          {input.percentOwned < 25 || !input.percentOwned || input.percentOwned > 100 || isNaN(input.percentOwned) ? (
            <p className="subscript">Enter a number between 25-100.</p>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default IsBeneficialOwner
