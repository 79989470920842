import React from "react"
import "../assets/styles/components/accountInput.scss"

const AccountInput = (props: { [key: string]: any }) => {
  const { name, label, checked, extraText, type, value, onChange, required } = props

  return (
    <div className={type === "checkbox" ? "account-input-checkbox" : "account-input"}>
      <input
        className={
          type === "checkbox"
            ? "account-checkbox-input"
            : label
            ? "account-form-input account-form-input-pad"
            : "account-form-input"
        }
        type={type}
        name={name}
        defaultValue={value}
        checked={checked === true ? true : false}
        placeholder={"  "}
        onChange={onChange}
        required={required === true ? true : false}
      />
      <label className={type === "checkbox" ? "account-checkbox-label" : "account-form-label"} htmlFor={name}>
        {" "}
        {label}
        {extraText ? (
          <div>
            <span className="form-extraText">{extraText}</span>
          </div>
        ) : null}
      </label>
    </div>
  )
}

export default AccountInput
