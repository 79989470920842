import { setDoc, collection, doc, query, getDocs, getDoc, deleteDoc } from "firebase/firestore"
import { Dispatch } from "@reduxjs/toolkit"
import { errorFactory, userLogin } from "../../duck/auth/index.td"

import { db } from "../index"
import { userKycActions } from "../../duck/profile"
import { kycProfile } from "../../duck/profile/index.td"
import { FirebaseError } from "firebase/app"

const newUser = async (uid: string, email: string) => {
  const newUserRef = doc(db, "users-KYC", uid)
  await setDoc(newUserRef, {
    level: 0,
    hostType: "Single Professional",
    email: email
  })
}

const transaction = async (myCollection: any) => {
  const trx = query(myCollection)
  const querySnapshot = await getDocs(trx)
  const userData: Record<string, unknown> = {}
  querySnapshot.forEach(doc => {
    userData[doc.id] = doc.data()
  })
  return userData
}

const getKycUser = async (uid: string) => {
  const docRef = doc(db, "users-KYC", `${uid}`)

  let userData
  try {
    const docSnap = await getDoc(docRef)
    userData = docSnap.data()
  } catch (error) {
    console.log(error)
  }

  return userData
}

const CurrentKycTransaction = async (docRef: any) => {
  const trx = query(collection(docRef, "stages"))
  const querySnapshot = await getDocs(trx)
  const userData: Record<string, unknown> = {}
  querySnapshot.forEach(doc => {
    userData[doc.id] = doc.data()
  })
  return userData
}

export const handleStartKYC = (dispatch: Dispatch) => async (kycProfileStatus: kycProfile, userStatus: userLogin) => {
  const {
    user: { uid, email }
  } = userStatus

  const userData = await getKycUser(uid)

  if (!userData) {
    await newUser(uid, email)
    const userData = await getKycUser(uid)
    dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: userData }))
  } else {
    handleCurrentKyc(dispatch)(kycProfileStatus, userStatus)
  }
}

const handleCurrentKyc = (dispatch: Dispatch) => async (kycProfileStatus: kycProfile, userStatus: userLogin) => {
  const {
    user: { uid }
  } = userStatus

  const userData = await getKycUser(uid)

  const docRef = doc(db, "users-KYC", `${uid}`)

  const userKycData = await CurrentKycTransaction(docRef)
  const currentKycData = { ...userKycData, ...userData }

  dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: currentKycData }))
}

export const handleHostType =
  (dispatch: Dispatch) =>
  async (kycProfileStatus: kycProfile, formInput: Record<string, unknown>, userStatus: userLogin) => {
    const {
      user: { uid }
    } = userStatus

    let code: errorFactory = {}

    try {
      if (formInput.form === "hostType") {
        const newUserRef = doc(db, "users-KYC", uid)
        await setDoc(
          newUserRef,
          {
            hostType: formInput.hostType,
            isNonProfit: formInput.isNonProfit || false,
            regType: ""
          },
          { merge: true }
        )
        const stagesRef = collection(newUserRef, "stages")
        await deleteDoc(doc(stagesRef, "businessInfo"))
        await deleteDoc(doc(stagesRef, "beneficialOwners"))
        await deleteDoc(doc(stagesRef, "accountManagers"))

        await setDoc(
          newUserRef,
          {
            level: 0
          },
          { merge: true }
        )
      }

      if (formInput.form === "regType") {
        const newUserRef = doc(db, "users-KYC", uid)
        await setDoc(
          newUserRef,
          {
            regType: formInput.regType
          },
          { merge: true }
        )
      }

      const userData = await getKycUser(uid)

      const docRef = doc(db, "users-KYC", `${uid}`)

      const { yourInfo }: any = await CurrentKycTransaction(docRef)
      const currentKycData = { yourInfo, ...userData }

      dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: currentKycData }))
    } catch (error: errorFactory) {
      code = { code: error?.code }
      if (error instanceof FirebaseError) dispatch(userKycActions.kycFailed(code))
    }
  }

export const handleKycNextBtn =
  (dispatch: Dispatch) =>
  async (input: Record<string, unknown>, kycProfileStatus: kycProfile, userStatus: userLogin) => {
    const {
      user: { uid }
    } = userStatus

    let nextKyclevel = kycProfileStatus?.profile.level
    if (kycProfileStatus?.profile.level >= (input?.formLevel as number) || kycProfileStatus?.profile.level === 4) {
      nextKyclevel = kycProfileStatus?.profile.level
    } else {
      nextKyclevel = kycProfileStatus?.profile.level + 1
    }

    let code: errorFactory = {}
    try {
      const userRef = doc(db, "users-KYC", uid)
      const stagesRef = collection(userRef, "stages")

      const yourInfoRef = doc(stagesRef, "yourInfo")
      const businessInfoRef = doc(stagesRef, "businessInfo")
      const beneficialOwnersRef = doc(stagesRef, "beneficialOwners")
      const accountManagersRef = doc(stagesRef, "accountManagers")

      let currentBeneficialOwners = []
      try {
        const docSnap = await getDoc(beneficialOwnersRef)
        currentBeneficialOwners = docSnap.data()?.otherBeneficialOwners
      } catch (error: errorFactory) {
        code = { code: error?.code }
        if (error instanceof FirebaseError) dispatch(userKycActions.kycFailed(code))
      }

      let currentAccountManagers = []
      try {
        const docSnap = await getDoc(accountManagersRef)
        currentAccountManagers = docSnap.data()?.accountManagers
      } catch (error: errorFactory) {
        code = { code: error?.code }
        if (error instanceof FirebaseError) dispatch(userKycActions.kycFailed(code))
      }

      switch (input.formLevel) {
        case 1:
          await setDoc(
            yourInfoRef,
            {
              firstName: input?.firstName,
              lastName: input?.lastName,
              homeAddress: {
                country: input?.country,
                street: input?.street,
                apartmentSuite: input?.apartmentSuite ?? "",
                city: input?.city,
                state: input?.state,
                zipCode: input?.zipCode
              },
              dateOfBirth: input?.dateOfBirth,
              placeOfBirth: input?.placeOfBirth,
              citizenship: input?.citizenship,
              tin: input?.tin ?? "",
              isAuthorized: input?.isAuthorizedManager ?? false,
              isComplete: false,
              isDraft: false
            },
            { merge: true }
          )
          await setDoc(
            userRef,
            {
              level: nextKyclevel
            },
            { merge: true }
          )
          break

        case 2:
          await setDoc(
            businessInfoRef,
            {
              legalBusinessName: input.legalBusinessName,
              sameName: input.sameName || false,
              tradingName: input.tradingName || input.legalBusinessName,
              businessRegNumber: input.businessRegNumber,
              taxIdNumber: input.taxIdNumber,
              vatId: input.vatId || "",
              dateOfIncorporation: input.dateOfIncorporation || "",
              officeAddress: {
                officeCountry: input.officeCountryRegion,
                officeStreet: input.officeStreet,
                officeApartmentSuite: input.officeApt_suite || "",
                officeCity: input.officeCity,
                officeState: input.officeState,
                officeZipCode: input.officeZipCode
              },
              sameAddress: input.sameAddress || false,
              tradingAddress: {
                tradingCountry: input.tradingCountryRegion || input.officeCountryRegion,
                tradingStreet: input.tradingStreet || input.officeStreet,
                tradingApartmentSuite: input.tradingApt_suite || input.officeApt_suite || "",
                tradingCity: input.tradingCity || input.officeCity,
                tradingState: input.tradingState || input.officeCity,
                tradingZipCode: input.tradingZipCode || input.officeZipCode
              },
              website: input.website || "",
              isComplete: false,
              isDraft: false
            },
            { merge: true }
          )
          await setDoc(
            userRef,
            {
              level: nextKyclevel
            },
            { merge: true }
          )
          break

        case 3:
          await setDoc(beneficialOwnersRef, {
            isBeneficialOwner: input.isBeneficialOwner,
            percentOwned: input.percentOwned
          })
          await setDoc(
            userRef,
            {
              level: nextKyclevel
            },
            { merge: true }
          )
          break

        case 4:
          await setDoc(
            beneficialOwnersRef,
            {
              //level 3+
              otherBeneficialOwners: [
                ...(currentBeneficialOwners === undefined ? [] : currentBeneficialOwners),
                {
                  beneficialFirstName: input.beneficialFirstName,
                  beneficialLastName: input.beneficialLastName,
                  beneficialPercentOwned: input.beneficialPercentOwned,
                  beneficialHomeAddress: {
                    country: input.beneficialCountry,
                    street: input.beneficialStreet,
                    apartmentSuite: input.beneficialApt_suite || "",
                    city: input.beneficialCity,
                    state: input.beneficialState,
                    zipCode: input.beneficialZipCode
                  },
                  beneficialDateOfBirth: input.beneficialDateOfBirth,
                  beneficialPlaceOfBirth: input.beneficialPlaceOfBirth,
                  beneficialCitizenship: input.beneficialCitizenship
                }
              ]
            },
            { merge: true }
          )
          break
        case 5:
          await setDoc(
            accountManagersRef,
            {
              hasAccountManager: input.hasAccountManager
            },
            { merge: true }
          )

          await setDoc(
            userRef,
            {
              level: nextKyclevel
            },
            { merge: true }
          )
          break

        case 6:
          await setDoc(
            accountManagersRef,
            {
              otherAccountManagers: [
                ...(currentAccountManagers === undefined ? [] : currentAccountManagers),
                {
                  managerFirstName: input.managerFirstName,
                  managerLastName: input.managerLastName,
                  managerCountry: input.managerCountry,
                  managerStreet: input.managerStreet,
                  managerCity: input.managerCity,
                  managerState: input.managerState,
                  managerZipCode: input.managerZipCode,
                  managerPlaceOfBirth: input.managerPlaceOfBirth,
                  managerCitizenship: input.managerCitizenship
                }
              ]
            },
            { merge: true }
          )

          break

        default:
          break
      }

      const userKycData = await transaction(stagesRef)
      const userData = await getKycUser(uid)
      const updatedUserData = { ...userKycData, ...userData }
      dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: updatedUserData }))
    } catch (error: errorFactory) {
      code = { code: error?.code }
      if (error instanceof FirebaseError) dispatch(userKycActions.kycFailed(code))
    }
  }

export const deleteUserBeneficial =
  (dispatch: Dispatch) => async (userStatus: userLogin, kycProfileStatus: kycProfile) => {
    const {
      user: { uid }
    } = userStatus

    const userRef = doc(db, "users-KYC", uid)
    const stagesRef = collection(userRef, "stages")
    const beneficialOwnersRef = doc(stagesRef, "beneficialOwners")

    await setDoc(
      beneficialOwnersRef,
      {
        isBeneficialOwner: "no",
        percentOwned: ""
      },
      { merge: true }
    )

    const userKycData = await transaction(stagesRef)
    const userData = await getKycUser(uid)
    const updatedUserData = { ...userKycData, ...userData }
    dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: updatedUserData }))
  }

export const deleteBeneficial =
  (dispatch: Dispatch) => async (beneficials: any, userStatus: userLogin, kycProfileStatus: kycProfile) => {
    const {
      user: { uid }
    } = userStatus

    const userRef = doc(db, "users-KYC", uid)
    const stagesRef = collection(userRef, "stages")
    const beneficialOwnersRef = doc(stagesRef, "beneficialOwners")

    await setDoc(
      beneficialOwnersRef,
      {
        otherBeneficialOwners: [...beneficials]
      },
      { merge: true }
    )

    const userKycData = await transaction(stagesRef)
    const userData = await getKycUser(uid)
    const updatedUserData = { ...userKycData, ...userData }
    dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: updatedUserData }))
  }

export const deleteManager =
  (dispatch: Dispatch) => async (managers: any, userStatus: userLogin, kycProfileStatus: kycProfile) => {
    const {
      user: { uid }
    } = userStatus

    const userRef = doc(db, "users-KYC", uid)
    const stagesRef = collection(userRef, "stages")
    const accountManagersRef = doc(stagesRef, "accountManagers")

    await setDoc(
      accountManagersRef,
      {
        otherAccountManagers: [...managers]
      },
      { merge: true }
    )

    const userKycData = await transaction(stagesRef)
    const userData = await getKycUser(uid)
    const updatedUserData = { ...userKycData, ...userData }
    dispatch(userKycActions.kycSuccess({ ...kycProfileStatus, profile: updatedUserData }))
  }
